<template>
  <div class="lm--wrapper-content-app">
    <template v-if="currentParent">
      <v-container>
        <TitleSection :title="currentParent.DESCRIPCION" :subtitle="currentParent.DIRECCION" :btnBack="true" />
      </v-container>
      <v-container v-if="parkList && parkList.length > 0">
        <v-row>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="12"
            v-for="(item, idx) in parkList"
            :key="idx + 250"
          >
            <CardSite
              :logo="item.URLFOTOLOGO"
              :name="item.DESCRIPCION"
              :phrase="item.FRASE"
              :img="item.URLFOTO"
              :uriPay="item.SUBDOMINIO"
              :linkPhase="linkPhase"
              :object="item"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <p class="text-center">
          0 parques encontrados
        </p>
      </v-container>
    </template>
    <template v-if="!currentParent && !status">
      <v-container>
        <p>Oops, parque no encontrado!</p>
      </v-container>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleSection from "@/components/titles/TitleSection";
import CardSite from "@/components/cards/CardSite";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "Parks",
  components: {
    TitleSection,
    CardSite,
  },
  data() {
    return {
      status: true,
    }
  },
  computed: {
    ...mapGetters({
      parentsList: "getParents",
      parksList: "getParks",
      lang: "getLanguage",
      accTools: "getAccesibilityTool"
    }),
    ...mapState({
      baseURL: (state) => state.baseURL,
      currentParent: function (state) {
        const { params } = this.$route;
        const parent = this.parentsList.find(p => p.ABREVIATURA == params.parent_name);
        if(!parent) return null;
        this.status = false;
        return parent;
      }, 
      parkList: function (state) {
        if(this.currentParent && state.parkList && state.parkList.length > 0) 
          return [...state.parkList].filter(p => p.IDPARQUEPADRE == this.currentParent.IDPARQUE)

        return [];
      },  
      linkPhase: (state) => state.linkPhase[state.lang],
    }),
  },
  mounted() {
    setTimeout(() => {
      this.status = false;
    }, 5000);

    setTimeout(() => {
      this.accTools.setReadableElements(
        ".lm--left-content img, .lm--title, .copy, .lm--author, .lm--datalist-item, .lm--searcher"
      );
      this.accTools.restart(this.lang);
    }, 1000);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  },

};
</script>

<style lang="scss" scoped></style>
